import React from 'react';

/**
 * Footer component displaying the copyright information.
 * 
 * @returns {ReactElement} The Footer component with copyright text.
 */
function Footer() {
    return (
      <footer>
        <p>&copy; 2024 Steven Barbour. All rights reserved.</p>
      </footer>
    );
  }
  
  export default Footer;
