import React from 'react';

/**
 * A simple navigation component for the resume app. It renders a set of buttons
 * that allow the user to navigate to different sections of the resume.
 *
 * @param {function} onSectionChange The function to call when a button is
 * clicked. It should take a string argument that represents the section to
 * navigate to.
 *
 * @returns {ReactElement} The navigation component.
 */
function Navigation({ onSectionChange }) {
  return (
    <nav>
      <button onClick={() => onSectionChange('summary')}>Profile Summary</button>
      <button onClick={() => onSectionChange('experience')}>Professional Experience</button>
      <button onClick={() => onSectionChange('education')}>Education</button>
      <button onClick={() => onSectionChange('certifications')}>Certifications</button>
      <button onClick={() => onSectionChange('technical')}>Technical Skills</button>
      <button onClick={() => onSectionChange('additional')}>Additional Skills</button>
    </nav>
  );
}

export default Navigation;
