import React from 'react';

/**
 * Header component displaying the name and title of the engineer.
 * @returns {ReactElement} The Header component with name and title.
 */
function Header() {
  return (
    <header>
      <h1>Steven Barbour</h1>
      <h2>Network / Systems / Software Engineer</h2>
          
    </header>
  );
}

export default Header;
