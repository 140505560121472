import React from 'react';

/**
 * Section component that renders a section of the resume.
 *
 * @param {string} id - The unique identifier for the section.
 * @param {string} title - The title of the section.
 * @param {ReactNode} children - The content to be displayed within the section.
 * @param {boolean} isActive - Determines if the section is currently active and visible.
 *
 * @returns {ReactElement} The Section component.
 */
function Section({ id, title, children, isActive }) {
  return (
    <section id={id} className={`content-section ${isActive ? 'active' : 'hidden'}`}>
      <h3>{title}</h3>
      {children}
    </section>
  );
}

export default Section;
