import React, { useState } from 'react';
import Header from './components/Header';
import Navigation from './components/Navigation.jsx';
import Section from './components/Section';
import Footer from './components/Footer';
import './App.css';

/**
 * The main App component. It renders the header, navigation, and main sections of
 * the resume, as well as the footer. It also handles section changes by setting
 * the active section in the component state and scrolling to the top of the page.
 *
 * @returns {ReactElement} The App component.
 */
function App() {
  const [activeSection, setActiveSection] = useState('summary');

  /**
   * Handles a section change by setting the active section in the component
   * state and scrolling to the top of the page.
   *
   * @param {string} sectionId The ID of the section to navigate to.
   */
  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="App">
      <Header />
      <Navigation onSectionChange={handleSectionChange} />
      <main className="main">
        <Section id="summary" title="Profile Summary" isActive={activeSection === 'summary'}>
          <p>Experienced Network and Systems Engineer with over 20 years in network engineering, cloud infrastructure,
              and software development. Proven track record in AWS cloud services, networking, and system
              optimization, with strong skills in troubleshooting, collaborative development, and high-level
              architecture.</p>
        </Section>

        <Section id="experience" title="Professional Experience" isActive={activeSection === 'experience'}>
          <h4>Amazon</h4>
          <p><em><strong>Cloud Support Engineer I (AWS)</strong></em><br />2024 – June</p>
          <ul>
            <li>Provided enterprise-level support across AWS services (EC2, S3, DynamoDB, Lambda) focusing on
                network and Linux-specific platforms.</li>
          </ul>
          <br />
          <p><em><strong>Software Development Engineer I (Intern)</strong></em><br />2023 – 2024</p>
          <ul>
            <li>Collaborated on AWS cloud-based applications, building UML class diagrams and sequence diagrams for
                new features.</li>
            <li>Developed and deployed full-stack solutions, applying different languages and frameworks.</li>
          </ul>
          <br />
          <p><em><strong>Amazon Technical Academy</strong></em><br />2022 – 2023</p>
          <ul>
            <li>Completed a competitive, 10-month immersive software development bootcamp for Amazon employees,
                focusing on AWS technologies, Java, and full-stack development.</li>
            <li>Built and deployed functional API endpoints with comprehensive unit and integration testing to
                ensure reliability and performance.</li>
            <li><strong>Core Skills:</strong> AWS DynamoDB, cloud provisioning, Java (collections, data structures,
                algorithms), and problem-solving.</li>
            <li><strong>Development Tools:</strong> Brazil, Crux, Docker, Git, and Dagger dependency injection.</li>
          </ul>
          <br />
          <p><em><strong>IT Support Engineer II</strong></em><br />2020 – 2022</p>
          <ul>
            <li>Installed, configured, and maintained computer systems, network connections, and critical
                applications to support high-performance operations.</li>
            <li>Ensured 24/7 network and server uptime, troubleshooting issues in a high-pressure, on-call rotation
                environment to minimize downtime.</li>
            <li>Streamlined resolution processes and standardized support procedures, resulting in more efficient
                incident response and improved service consistency.</li>
            <li>Collaborated cross-functionally to implement system-wide improvements that enhanced operational
                efficiency and user satisfaction.</li>
            <li>Documented technical issues and resolutions meticulously, creating a detailed log for tracking and
                continuous improvement.</li>
            <li>Researched and introduced new technologies to optimize support processes, improving response times
                and service quality for end-users.</li>
          </ul>
          <br />
          <h4>Smart City Telecom</h4>
          <p><em><strong>Senior Network / System Engineer</strong></em><br />2016 – 2020</p>
          <ul>
            <li>Architected and maintained an enterprise-level Cisco L2/L3 network and Linux server clusters,
                optimizing BGP configurations to ensure seamless connectivity and system performance.</li>
            <li>Enhanced network uptime and resilience by implementing proactive monitoring and robust DDoS
                mitigation strategies, contributing to uninterrupted service during peak usage.</li>
            <li>Led skill-building initiatives by mentoring engineers and NOC technicians in advanced
                troubleshooting and network configuration, boosting team efficiency and technical expertise.</li>
          </ul>
          <br />
          <p><em><strong>Network / System Engineer</strong></em><br />2012 – 2016</p>
          <ul>
            <li>Designed, configured, and maintained L2/L3 (IPv4/6, OSPF, BGP) networks and Linux servers for
                internal and external clients using Cisco core infrastructure and multi-vendor hardware.</li>
            <li>Developed comprehensive network diagrams and documentation to ensure clarity and support ongoing
                operations.</li>
            <li>Provided ongoing maintenance and technical support for enterprise networks and Linux clusters,
                ensuring high availability and performance.</li>
            <li>Mentored NOC engineers and technicians on troubleshooting, network configurations, and new project
                implementations, enhancing team capabilities.</li>
          </ul>
          <br />
          <p><em><strong>NOC Engineer</strong></em><br />2003 – 2012</p>
          <ul>
            <li>Supported engineering projects by maintaining and configuring Windows/Linux servers, L2/L3 switches,
                and other network equipment.</li>
            <li>Monitored network and server performance, swiftly troubleshooting issues to prevent and resolve
                system failures, degradation, or outages.</li>
            <li>Mentored NOC technicians on complex troubleshooting and escalated support issues, fostering a
                proactive and skilled support team.</li>
          </ul>
        </Section>

        <Section id="education" title="Education" isActive={activeSection === 'education'}>
          <p><strong>Amazon Technical Academy</strong><br />Software Development Program, Certificate (2023)</p>
          <br />
          <p><strong>Valencia State College</strong><br />Technical Certificate, Cisco CCNA (2011 – 2012)</p>
          <br />
          <p><strong>Daytona State College</strong><br />High School Diploma (1993)</p>
        </Section>

        <Section id="certifications" title="Certifications" isActive={activeSection === 'certifications'}>
          <p><strong>Zabbix, LLC</strong><br />Zabbix Certified Specialist (2018)<br />Zabbix Certified Professional (2018)</p>
          <br />
          <p><strong>Cisco Systems, Inc.</strong><br />Cisco Certified Network Associate, CCNA (2016)<br />Cisco Certified Network Professional, CCNP (2016)</p>
          <br />
          <p><strong>CompTIA</strong><br />CompTIA A+ (1998)</p>
        </Section>

        <Section id="technical" title="Technical Skills" isActive={activeSection === 'technical'}>
          <p><strong>Cloud Platforms:</strong></p>
          <ul><li>Azure (Fundamentals), AWS (EC2, S3, DynamoDB, Lambda, AppSync)</li></ul>
          <br />
          <p><strong>Systems:</strong></p>
          <ul><li>Linux, Windows Server, Cisco Routing/Switching (L2/L3)</li></ul>
          <br />
          <p><strong>Networking Protocols:</strong></p>
          <ul><li>TCP/IP, OSPF, BGP, DNS, DHCP, IPAM, LTM/GSLB, VPN</li></ul>
          <br />
          <p><strong>Programming:</strong></p>
          <ul><li>HTML, CSS, Java, JavaScript, TypeScript, GraphQL, React, Ruby on Rails</li></ul>
          <br />
          <p><strong>Development Tools:</strong></p>
          <ul><li>Brazil, Docker, Git, Crux, Dagger, Pipelines</li></ul>
        </Section>

        <Section id="additional" title="Additional Skills" isActive={activeSection === 'additional'}>
          <p>Critical Thinking, Collaboration, Project Management, Leadership, Agile Methodologies</p>
        </Section>
      </main>
      <Footer />
    </div>
  );
}

export default App;
